import React from "react";
import * as aboutStyles from "./About.module.scss";

function About() {
  return (
    <section className={aboutStyles.about}>
      <h2>About</h2>
      <p>
        I'm a student in the Software Development Department at the{" "}
        <i>
          <strong> IT Academy Step Cambodia</strong>
        </i>
        .
      </p>
      <br />
      <br />
      <p>
        Regarding development, I prefer working within{" "}
        <i>
          <strong>Full Stack Web and Desktop Application</strong>
        </i>
        , hence I'm familiar with{" "}
        <strong>TypeScript, ReactJS, Gatsby, Node.js, Winforms, WPF</strong>. I
        love working on both Front-End and Back-End. Nevertheless, I'm always
        willing to explore and learn new skills or technologies.
      </p>
      <br />
      <br />
      <p>
        Although I spend almost every waking hour studying articles, exploring a
        new technology or building something fun, I also enjoy doing sports,
        reading and spending time with my friends.
      </p>
    </section>
  );
}

export default About;
