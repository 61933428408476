import React, { useEffect, useRef, useState } from "react";
import * as progressbarStyles from "./ProgressBar.module.scss";
function ProgressBar({ subject, value }) {
  const progressBarRef = useRef();
  const [animate, setAnimate] = useState(false);
  const animationHandler = () => {
    if (progressBarRef.current?.offsetHeight) {
      if (window.scrollY >= (progressBarRef.current.offsetHeight * 8) / 2)
        setAnimate(true);
      else setAnimate(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", animationHandler);
    return window.removeEventListener("scroll", animationHandler, true);
  }, []);

  return (
    <div className={progressbarStyles.ProgressBar} ref={progressBarRef}>
      <h3>{subject}</h3>
      <span>
        <span
          className={animate ? progressbarStyles.animate : undefined}
          style={{
            "--value": value + "%",
          }}
        ></span>
      </span>
    </div>
  );
}

export default ProgressBar;
