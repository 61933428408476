// extracted by mini-css-extract-plugin
export var intro = "index-module--intro--1QDXF";
export var splash = "index-module--splash--2u-so";
export var intro_social = "index-module--intro_social--3eTDx";
export var intro_text = "index-module--intro_text--WbwIQ";
export var intro_images = "index-module--intro_images--3kKXi";
export var plant_left = "index-module--plant_left--2pDvl";
export var plant = "index-module--plant--1rKpL";
export var plant_right = "index-module--plant_right--1WKql";
export var me = "index-module--me--mMVTX";
export var clock_arrow = "index-module--clock_arrow--3RsVK";
export var clock = "index-module--clock--3Al05";
export var mainHead = "index-module--main-head--1rxHZ";
export var elena = "index-module--elena--2DM10";
export var travelly = "index-module--travelly--cLjF8";