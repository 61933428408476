import React from "react";
import * as contactStyles from "./Contact.module.scss";
import ContactSvg from "../assets/img/contact.svg";
function Contact() {
  return (
    <section id="contact" className={contactStyles.form_section}>
      <form
        className={contactStyles.helperForm}
        name="contact"
        data-netlify={true}
        netlify-honeypot="bot-field"
        hidden={true}
      >
        <input type="text" name="name" />
        <input type="email" name="email" />
        <textarea name="message"></textarea>
      </form>

      <img src={ContactSvg} alt="ContactSvg"></img>
      <form method="POST" name="contact">
        <input type="hidden" name="form-name" value="contact" />
        <label htmlFor="name">Name</label>
        <input type="text" name="name" id="name" required />
        <label htmlFor="email">Email</label>
        <input type="email" name="email" id="email" required />
        <label htmlFor="message">Message</label>
        <textarea name="message" id="message" cols="30" rows="10"></textarea>
        <button type="submit">Submit</button>
      </form>
    </section>
  );
}

export default Contact;
