import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import * as skillStyles from "./Skill.module.scss";
import ProgressBar from "./ProgressBar";

function Skill() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSkills(sort: { fields: createdAt, order: ASC }) {
        nodes {
          name
          value
        }
      }
    }
  `);
  const skills = data.allContentfulSkills.nodes;
  const firstIndex = 0;
  const secondIndex = Math.ceil(data.allContentfulSkills.nodes.length / 2);
  const thirdIndex = data.allContentfulSkills.nodes.length;

  return (
    <section className={skillStyles.skill_container}>
      <div className={skillStyles.skill}>
        <h2>Skills</h2>
        <div>
          <div>
            {skills.slice(firstIndex, secondIndex).map((skill) => (
              <ProgressBar
                key={skill.name}
                subject={skill.name}
                value={skill.value}
              />
            ))}
          </div>
          <div>
            {skills.slice(secondIndex, thirdIndex).map((skill) => (
              <ProgressBar
                key={skill.name}
                subject={skill.name}
                value={skill.value}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Skill;
